@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.MapLabel {
  font-family: 'Nunito', sans-serif !important;
  font-size: 20px !important;
  background-color: #ED6B16;
  padding: 5px;
  border-radius: 25px;
  -webkit-transform: translate(0, 50px);
          transform: translate(0, 50px);
}

